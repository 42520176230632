// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-tutoriel-js": () => import("/opt/build/repo/src/templates/tutoriel.js" /* webpackChunkName: "component---src-templates-tutoriel-js" */),
  "component---src-pages-cavalier-js": () => import("/opt/build/repo/src/pages/cavalier.js" /* webpackChunkName: "component---src-pages-cavalier-js" */),
  "component---src-pages-club-js": () => import("/opt/build/repo/src/pages/club.js" /* webpackChunkName: "component---src-pages-club-js" */),
  "component---src-pages-contact-js": () => import("/opt/build/repo/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-js": () => import("/opt/build/repo/src/pages/legal.js" /* webpackChunkName: "component---src-pages-legal-js" */),
  "component---src-pages-shalee-js": () => import("/opt/build/repo/src/pages/shalee.js" /* webpackChunkName: "component---src-pages-shalee-js" */),
  "component---src-pages-tutoriels-js": () => import("/opt/build/repo/src/pages/tutoriels.js" /* webpackChunkName: "component---src-pages-tutoriels-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

